import '@angular/common/locales/global/fr';
import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { SidebarShellComponent } from './core/sidebar-shell/sidebar-shell.component';
import { HasPermissionGuard } from './shared/guards/has-permission.guard';
import { TEACHERS_ROUTES } from './teachers/teachers.routes';

export const routes: Routes = [
  {
    path: '',
    component: SidebarShellComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'students',
        canActivate: [HasPermissionGuard],
        data: {
          permission: 'enroll:students',
        },
        loadChildren: () => import('./students/students.routes').then((m) => m.STUDENTS_ROUTES),
      },
      {
        path: 'offer',
        canActivate: [HasPermissionGuard],
        data: {
          permission: 'read:offers',
        },
        loadChildren: () => import('./academics/academics.routes').then((m) => m.ACADEMICS_ROUTES),
      },
      {
        path: 'settings',
        canActivate: [HasPermissionGuard],
        data: {
          permission: 'write:settings',
        },
        loadChildren: () => import('./settings/settings.routes').then((m) => m.SETTINGS_ROUTES),
      },
      {
        path: 'stats',
        canActivate: [HasPermissionGuard],
        data: {
          permission: 'read:stats',
        },
        loadChildren: () => import('./stats/stats.routes').then((m) => m.STATS_ROUTES),
      },
      {
        path: 'grades',
        canActivate: [HasPermissionGuard],
        data: {
          permission: 'write:grades',
        },
        loadChildren: () => import('./grades/grades.routes').then((m) => m.GRADES_ROUTES),
      },
      {
        path: 'results',
        canActivate: [HasPermissionGuard],
        data: {
          permission: 'read:results',
        },
        loadChildren: () => import('./results/results.routes').then((m) => m.RESULTS_ROUTES),
      },
      {
        path: 'teachers',
        canActivate: [HasPermissionGuard],
        data: {
          permission: 'write:teachers',
        },
        loadChildren: () => TEACHERS_ROUTES,
      },
      {
        path: 'juries',
        canActivate: [HasPermissionGuard],
        data: {
          permission: 'write:jurys',
        },
        loadChildren: () => import('./juries/juries.routes').then((m) => m.JURIES_ROUTES),
      },
      {
        path: 'e-learning',
        canActivate: [HasPermissionGuard],
        data: {
          permission: 'use:e-learning',
        },
        loadChildren: () =>
          import('./e-learning/e-learning.routes').then((m) => m.E_LEARNING_ROUTES),
      },
      {
        path: 'my-faculty/students',
        canActivate: [HasPermissionGuard],
        data: {
          permission: 'read:my-faculty-students',
        },
        loadChildren: () =>
          import('./my-faculty/students/my-faculty-students.routes').then(
            (m) => m.MY_FACULTY_STUDENTS_ROUTES,
          ),
      },
      {
        path: 'my-faculty/results',
        canActivate: [HasPermissionGuard],
        data: {
          permission: 'read:my-faculty-results',
        },
        loadChildren: () =>
          import('./my-faculty/results/my-faculty-results.routes').then(
            (m) => m.MY_FACULTY_RESULTS_ROUTES,
          ),
      },
      {
        path: 'my-faculty/stats',
        canActivate: [HasPermissionGuard],
        data: {
          permission: 'read:my-faculty-stats',
        },
        loadChildren: () =>
          import('./my-faculty/stats/my-faculty-stats.routes').then(
            (m) => m.MY_FACULTY_STATS_ROUTES,
          ),
      },
      {
        path: 'candidature',
        canActivate: [HasPermissionGuard],
        data: {
          permission: 'read:candidates',
        },
        loadChildren: () =>
          import('./candidature/candidature.routes').then((m) => m.CANDIDATURES_ROUTES),
      },
      {
        path: 'accepted-candidates',
        canActivate: [HasPermissionGuard],
        data: {
          permission: 'enroll:students',
        },
        loadChildren: () =>
          import('./accepted-candidates/accepted-candidates.routes').then(
            (m) => m.ACCEPTED_CANDIDATES_ROUTES,
          ),
      },
      {
        path: 'communication',
        canActivate: [HasPermissionGuard],
        data: {
          permission: 'write:news',
        },
        loadChildren: () =>
          import('./communication/communication.routes').then((m) => m.COMMUNICATION_ROUTES),
      },
      {
        path: 'accounting',
        canActivate: [HasPermissionGuard],
        data: {
          permission: 'feature:accounting',
        },
        loadChildren: () =>
          import('./accounting/accounting.routes').then((m) => m.ACCOUNTING_ROUTES),
      },
      {
        path: '',
        loadComponent: () => import('./home/home.page').then((c) => c.HomePage),
      },
    ],
  },
  {
    path: 'not-found',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./core/not-found/not-found.component').then((m) => m.NotFoundComponent),
  },
  {
    path: 'enrollments-locked',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./core/enrollments-locked/enrollments-locked.page').then(
        (m) => m.EnrollmentsLockedPage,
      ),
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];
